import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { SectionTitle } from './SectionTitle'

export type PGallerySection = {
	children: React.ReactNode | React.ReactNode[]
}
export const GallerySection: React.FC<PGallerySection> = ({ children }) => {
	return <>
		<SectionTitle>~ Gallery ~</SectionTitle>

		<div className='flex flex-col items-stretch justify-start'>
			<p>Last updated: 21 Nov 2024</p>

			<GalleryItemBlock
				link='https://brickmyplan.syxworks.me'
				titleClassName='text-amber-800'
				className='hover:bg-amber-700/10 active:bg-amber-700/20'
				imgBlock={ <StaticImage
					src='../images/bmp.jpg'
					alt='"BrickMyPlan" app screenshot'
					placeholder='blurred'
					layout='constrained'
					className='border rounded-md border-amber-700/50'
				/> }
				title='BrickMyPlan'
				description={ <>
					<p className='mb-3'>An almost-complete startup MVP.</p>
					<p className='mb-3'>Log in with email <Code>testuser@syxworks.me</Code> and password <Code>password</Code>.</p>
					<p className='mb-3'>Enter your current income, monthly expenditures, loans, investments and returns, and watch as a Rust-powered calculator immediately charts out your estimated net worth from now till you are 100 years old. Drag the knobs to consider other career, investment plans, add your car, house, wedding or any other huge expenditure and watch as the graph instantly updates.</p>
				</>}
			/>

			<GalleryItemBlock
				link='https://ihaveadilemma.syxworks.me'
				titleClassName='text-sky-500'
				className='hover:bg-sky-600/10 active:bg-sky-600/20'
				imgBlock={<StaticImage
					src='../images/ihad-preview.png'
					alt='"I Have A Dilemma" app screenshot'
					placeholder='blurred'
					layout='constrained'
					className='border rounded-md border-slate-300'
				/>}
				title='I Have A Dilemma'
				description={<><p className='mb-3'>This page guides you to type down what your current dilemma is, the choices you have, and the pros and cons of each choice. You then get to compare them all.</p></>}
			/>

			<GalleryItemBlock
				link='https://outer-space.syxworks.me'
				className='hover:bg-slate-600/10 active:bg-slate-600/20'
				titleClassName='text-slate-700'
				imgBlock={ <StaticImage
					src='../images/syxly-outerspace-preview.jpg'
					alt='"Outer Space" app screenshot'
					placeholder='blurred'
					layout='constrained'
					className='rounded-md '
				/> }
				title='Outer Space.'
				description={ <>
					<p className='mb-3'>Gaze at the stars and feel for a moment our smallness<br />in this huge and magnificent universe.</p>
					<p className='mb-3'>Might be therapeutic.</p>
					<p className='mb-3'>Powered by NASA's Astronomy Photo of the Day (APOD).</p>
				</> }
			/>
		</div>
	</>
}

const GalleryItemBlock: React.FC<{imgBlock: React.ReactNode, title: React.ReactNode, description: React.ReactNode, link: string, titleClassName?: string, className?: string}> = ({ imgBlock, title, description, link, titleClassName, className }) => {
	return <a className={ `items-start w-full max-w-screen-md p-3 my-5 transition-colors rounded-md cursor-pointer ${ className ?? 'hover:bg-cyan-600/10 active:bg-cyan-600/20'}`} href={link} target='_blank' rel='noopener'>
		<div className='w-full min-w-0'>
			{ imgBlock }
		</div>
		<div className='h-3' />
		<div className='w-full min-w-0'>
			<div className={`mb-3 text-2xl font-bold text-center ${titleClassName ?? 'text-cyan-600'}`}>{ title }</div>
			<div className='text-center text-slate-800'>{ description }</div>
		</div>
	</a>
}

const Code: React.FC<{children: React.ReactNode}> = ({ children }) => <code className='px-1 text-xs rounded-md bg-slate-200 text-slate-800'>{ children }</code>