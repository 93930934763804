import React from 'react'

export type PNavBarLink = {
	children?: React.ReactNode
}
export const NavBarLink: React.FC<PNavBarLink> = ({ children }) => {
	return <div className="px-4 py-5 cursor-pointer group">
		<div className="text-cyan-600 transition-all after:content-[''] after:w-full block after:border-b-2 after:border-b-slate-300 after:absolute after:bottom-0 after:left-0 relative after:transform after:translate-y-5 group-hover:after:translate-y-2 after:opacity-0 group-hover:after:opacity-100 after:transition-all">
			{ children }
		</div>
	</div>
}
