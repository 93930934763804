import React from 'react'
import { Children } from 'utils/react-ext'

export type PSectionTitle = {
}
export const SectionTitle: React.FC<PSectionTitle & Children> = ({ children }) => {
	return <div className='w-full mb-5 text-2xl text-center text-cyan-600'>
		{ children }
	</div>
}
